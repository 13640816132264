<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="five"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <div class="titleTopCenterLeftBox" @click="goPremangeseeDtail">病例详情</div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
          <div class="iq-card-body">
            <div class="table-top flex-space-between">
              <el-page-header @back="goBack" :content="type == 1 ? '收费' : '退费'"> </el-page-header>
              <div>
                <el-button type="primary" size="small" @click="toCharge" v-if="type == 1">收费</el-button>
                <el-button type="primary" size="small" @click="toDrugRefundApply" v-if="type == 2 && detailInfo.state == 1">退费</el-button>
              </div>
            </div>
            <div class="table-line"></div>
            <div class="table-content">
              <div class="top-info flex-space-between">
                <div class="left-i">
                  <div class="flex-start">
                    <img v-if="patientInfo.sex" src="@/assets/img/touxiang03.png" />
                    <img v-else src="@/assets/img/touxiang04.png" />
                    <div class="userName">
                      {{ detailInfo.patientName ? detailInfo.patientName : "无" }}
                    </div>
                  </div>
                  <div class="info-div">
                    <span class="t1"
                      ><span class="t2">性别：</span><span v-if="patientId">{{ patientInfo.sex == true ? "男" : "女" }}</span
                      ><span v-else>无</span></span
                    >
                    <span class="t1"><span class="t2">年龄：</span>{{ patientInfo.age ? patientInfo.age : "无" }}</span>
                    <span><i class="el-icon-phone t2"></i>：{{ patientInfo.phone ? patientInfo.phone : "无" }}</span>
                  </div>
                </div>
                <div class="center-i">
                  <div class="t1">
                    <span class="t2">就诊类型：</span>
                    <template v-if="detailInfo.diagnoseType">复诊</template>
                    <template v-else>初诊</template>
                  </div>
                  <div class="t1">
                    <span class="t2">就诊时间：</span>
                    <span>{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</span>
                  </div>
                  <div class="t1">
                    <span class="t2">看诊医生：</span>
                    <span>{{ detailInfo.doctorName ? detailInfo.doctorName : "无" }}</span>
                    <span v-if="mZPrescriptionInfo.mzPrescriptionOut.userPhone">【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</span>
                  </div>
                </div>
                <div class="right-i">
                  <div class="t1">
                    <span class="t2">审核医生：</span>
                    <span>{{ mZPrintPrescription.doctorName ? mZPrintPrescription.doctorName : "无" }}</span>
                    <span v-if="mZPrintPrescription.doctorPhone">【{{ mZPrintPrescription.doctorPhone }}】</span>
                    <span>{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</span>
                  </div>
                  <!-- <div class="t1">
                    <span class="t2">审核时间：</span>
                    <span>{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</span>
                  </div> -->
                  <!-- <span class="t2">药剂师：</span>
                    <span>{{ mZPrintPrescription.pharmacistName ? mZPrintPrescription.pharmacistName : "无" }}</span>
                    <span v-if="mZPrintPrescription.pharmacistPhone">【{{ mZPrintPrescription.pharmacistPhone }}】</span> -->
                  <div class="t1">
                    <span class="t2">诊断：</span>
                    <span>{{ detailInfo.diagnoseResult ? detailInfo.diagnoseResult : "无" }}</span>
                  </div>
                </div>
              </div>
              <el-divider v-if="type == 1"></el-divider>
              <div class="numDiv numDiv2" v-if="type == 2">
                <div class="flex-start" v-for="(item, i) in detailInfo.payments" :key="i">
                  <!-- type 付款类型 0收费 1退费 2还款 -->
                  <div class="flex-start desc">
                    <div class="t1">
                      <span class="t2">
                        <span v-if="item.type == 0" class="type0">收费：</span>
                        <span v-if="item.type == 1" class="type1">退费：</span>
                        <span v-if="item.type == 2" class="type2">还款：</span>
                        时间：</span
                      >{{ item.paySuccessTime | timefilters }}
                    </div>
                    <div><span class="t2"> 操作员：</span>{{ item.userName }}</div>
                  </div>
                  <div class="flex-start center-num desc">
                    <div v-if="item.type == 1">
                      <span class="t2">应退：</span>
                      <span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</span>
                    </div>
                  </div>
                  <div class="flex-end right-num desc">
                    <div class="t1">付款方式：</div>
                    <div class="t3">
                      <span v-if="item.payWay == 0">余额</span>
                      <span v-if="item.payWay == 1">微信</span>
                      <span v-if="item.payWay == 2">支付宝</span>
                      <span v-if="item.payMoney >= 0">¥{{ item.payMoney ? item.payMoney.toFixed(2) : "0.00" }}</span>
                      <span v-if="item.payMoney < 0">¥0.00</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="infoDiv flex-space-between">
                <div>
                  <span class="t1">取药方式：</span>
                  <span class="t2" v-if="detailInfo.isSelef">自取 </span>
                  <span class="t2" v-else>快递 收件人信息：{{ detailInfo.takeAddress ? detailInfo.takeAddress : "无" }} </span>
                </div>
              </div>
              <div class="title" v-if="orderGrouped[2].length > 0">检查治疗项</div>
              <el-table :data="orderGrouped[2]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[2].length > 0" @selection-change="handleSelectionChange($event, 2)" ref="multipleSelection2" @select-all="selectAllChange($event, 2)">
                <!-- <el-table-column type="selection" width="55" :selectable="checkboxSelect" v-if="detailInfo.discountsMoney == 0"> </el-table-column> -->
                <el-table-column prop="entityName" label="检查名称"></el-table-column>
                <el-table-column prop="money" label="单价(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.money.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="数量" align="center"></el-table-column>
                <el-table-column prop="countMoney" label="总金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.countMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="orderState" label="状态" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                    <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                    <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <div class="title" v-if="orderGrouped[1].length > 0">处方药</div>
              <el-table :data="orderGrouped[1]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[1].length > 0" @selection-change="handleSelectionChange($event, 1)" ref="multipleSelection1" @select-all="selectAllChange($event, 1)">
                <!-- <el-table-column type="selection" width="55" :selectable="checkboxSelect" v-if="type == 1"> </el-table-column> -->

                <el-table-column prop="prescriptionType" label="处方类型">
                  <template slot-scope="scope">
                    <p v-if="scope.row.prescriptionType == 0">方剂处方</p>
                    <p v-if="scope.row.prescriptionType == 1">成药处方</p>
                    <p v-if="scope.row.prescriptionType == 2">输液处方</p>
                  </template>
                </el-table-column>
                <el-table-column prop="prescriptionName" label="处方名称" align="center"></el-table-column>
                <el-table-column prop="entityName" label="药品名称" align="center">
                  <template slot-scope="scope">
                    <p>{{ scope.row.entityName }} </p><p v-if="scope.row.remark ">/{{ scope.row.remark }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="specification" label="规格" align="center">
                  <template slot-scope="scope">
                    <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="money" label="单价(元)" align="center">
                  <template slot-scope="scope"> {{scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="amount" label="数量" align="center">
                  <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="countMoney" label="总金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.countMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="dosage" label="剂数" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.dosage ? scope.row.dosage : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="orderState" label="状态" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                    <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                    <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <div class="title" v-if="orderGrouped[0].length > 0">直接售药</div>
              <el-table :data="orderGrouped[0]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[0].length > 0" @selection-change="handleSelectionChange($event, 0)" ref="multipleSelection0" @select-all="selectAllChange($event, 0)">
                <!-- <el-table-column type="selection" width="55" :selectable="checkboxSelect" v-if="detailInfo.discountsMoney == 0"> </el-table-column> -->
                <el-table-column prop="entityName" label="药品名称"></el-table-column>
                <el-table-column prop="specification" label="规格" align="center">
                  <template slot-scope="scope">
                    <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="money" label="单价(元)" align="center">
                  <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="amount" label="数量" align="center">
                  <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="countMoney" label="总金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.countMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="orderState" label="状态" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                    <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                    <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <div class="title" v-if="orderGrouped[99].length > 0">其他收费</div>
              <el-table :data="orderGrouped[99]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[99].length > 0">
                <el-table-column prop="entityName" label="费用名称">
                  <template slot-scope="scope"> {{ scope.row.entityName }}<span style="color: red; font-size: 12px; margin-left: 10px" v-if="scope.row.orderType == 5 || scope.row.orderType == 8">(不可退)</span> </template>
                </el-table-column>
                <el-table-column prop="countMoney" label="金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.countMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="addTime" label="状态" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                    <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                    <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <div class="numDiv flex-end" v-if="type == 1">
                <div class="num1" style="margin-bottom: -10px">总金额:</div>
                <div class="inputNum" style="margin-right: 50px">
                  ￥
                  <span style="font-size: 25px"> {{ charge.totalMoney ? (charge.totalMoney - charge.totalPayMoney).toFixed(2)  : "0.00" }}</span>
                </div>
              </div>
              <div class="numDiv flex-end" v-if="type == 2">
                <div class="num1" style="margin-bottom: -10px">退费金额:</div>
                <div class="inputNum" style="margin-right: 50px">
                  ￥
                  <span style="font-size: 25px"> {{ detailInfo.showTotalMoney }}</span>
                </div>
              </div>
            </div>
            <el-dialog title="收费" :visible.sync="setChargeDialog" width="30%" :show-close="false">
              <el-form class="dialog-form" :model="charge" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
                <el-form-item label="应收金额" style="color: #ff0000" class="numT">
                  <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ? (charge.totalMoney - charge.totalPayMoney).toFixed(2) : "0.00" }}</span>
                </el-form-item>
                <template v-if="charge.credit">
                  <el-form-item label="姓名" prop="debtUserName">
                    <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="手机号" prop="debtUserPhone">
                    <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="挂账金额" prop="debtMoney">
                    <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1" :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
                  </el-form-item>
                </template>
                <el-form-item label="付款方式">
                  <el-radio-group v-model="charge.payWay"  @input='chargePayWayInput'>
                    <el-radio :label="1">微信</el-radio>
                    <el-radio :label="2">支付宝</el-radio>
                    <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
                  </el-radio-group>
                </el-form-item>
                <template v-if="charge.payWay == 0">
                  <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd" show-password style="width: 200px"></el-input><span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span> </el-form-item>
                </template>
                <template v-if="charge.payWay == 1">
                  <div class="flex-space-between-columns" v-if="qRcodeUrl">
                    <img style="width: 120px; height: 120px" :src="qRcodeUrl" />
                    <div>
                      <span>打开微信扫码支付~ </span>
                      <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                    </div>
                  </div>
                </template>
                <template v-if="charge.payWay == 2">
                    <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
                      <iframe
                        :srcdoc="qRcodeUrlZ"
                        frameborder="no"
                        border="0"
                        marginwidth="0"
                        marginheight="0"
                        scrolling="no"
                        width="200"
                        height="200"
                        style="overflow: hidden;margin:10px 0"
                      >
                      </iframe>
                      <div>
                        <span>打开支付宝扫码支付~ </span>
                        <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                      </div>
                    </div>
                </template>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
                <el-button type="primary" @click="SaveBtn('chargeForm')" :loading="nosucecessloading">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="退费" :visible.sync="setRefundDialog" width="30%">
              <el-form class="dialog-form" :model="refund" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
                <el-form-item label="退费金额" style="color: #ff0000" class="numT"> ￥{{ refund.totalMoney ? refund.totalMoney.toFixed(2) : "0.00" }} </el-form-item>
                <el-form-item label="退费方式" prop="type">
                  <el-radio-group v-model="refund.payments[0].payWay">
                    <el-radio :label="0">现金</el-radio>
                    <el-radio :label="1">微信</el-radio>
                    <el-radio :label="2">支付宝</el-radio>
                    <el-radio :label="3">银行卡</el-radio>
                    <el-radio :label="4">其他</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="CloseRefundBtn('chargeForm')">取 消</el-button>
                <el-button type="primary" @click="saveRefund('chargeForm')">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
import { Outpatient } from "@/components/Drug/Outpatient";
export default {
  components: {
    intNavheader,
  },
  data() {
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var UserDistributorltemDrug = new shoppingMall(this.TokenClient, this.Services.Drug);
    var drugPatient = new patientMain(this.TokenClient, this.Services.Authorization);
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var outpatientAuthorization = new Outpatient(this.TokenClient, this.Services.Authorization);
    return {
      drugPatientDomain: drugPatient,
      OutpatientDomain: outpatient,
      outpatientAuthorizationDomain: outpatientAuthorization,
      UserDistributorMain: UserDistributorltem,
      UserDistributorltemDrugMain: UserDistributorltemDrug,
      userInfo: this.$store.state.localData.userInfo,
      tableData: [],
      tableData: [],
      detailInfo: {
        gender: 0,
      },
      userInfo: {},
      setChargeDialog: false,
      setRefundDialog: false,
      assetsBalanceIsTrue: false,
      formLabelWidth: "100px",
      form: {
        id: 0,
        payments: [
          {
            discountsMoney: 0, //优惠金额
            debtUserName: null,
            debtUserPhone: null,
            debtMoney: 0, //挂账总金额
            payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
            payMoney: 0, //支付金额
            totalMoney: 0,
            payment: null, //输入金额
            change: 0, //找零
            credit: false,
          },
        ],
        details: [],
      },
      charge: {
        id: 0,
        details: [],
        discountsMoney: 0, //优惠金额
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
        credit: false,
      },
      refund: {
        id: parseInt(this.$route.query.orderId),
        totalMoney: null,
        details: [],
        payments: [
          {
            payWay: 1,
            payMoney: 0,
          },
        ],
      },
      orderId: this.$route.query.orderId,
      type: this.$route.query.type, //1收费2退费
      selectDetail: [],
      rules: {
        debtUserName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        debtUserPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        payPwd: [
          { required: true, message: "请输入支付密码", trigger: "blur" },
          {
            pattern: /(^[0-9]{6}$)/,
            message: "请输入6位数字",
            trigger: "blur",
          },
        ],

        debtMoney: [{ required: true, message: "请输入挂账金额", trigger: "blur" }],
        payment: [{ required: true, message: "请输入付款金额", trigger: "blur" }],
      },
      orderGrouped: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] },
      multipleSelection0: [],
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      multipleSelection4: [],
      multipleSelection5: [],
      patientId: 0,
      patientDiagnoseId: 0,
      patientInfo: {},
      patientDiagnoseInfo: {},
      mZPrescriptionInfo: {
        mzCheckItems: [],
        mzOtherCharges: [],
        mzPrescriptionOut: {
          mzPrescriptionSYGroup: [],
          mzPrescriptionCY: [],
          mzPrescriptionYP: [],
        },
      },
      mZPrintPrescription: {},
      moment: require("moment"),
      UserBalance: {
        assetsBalance: 0,
      },
      qRcodeUrl: "",
      weChatInfo: "",
      timer: null,
      timerZ:null,
      qRcodeUrlZ:"",
      nosucecessloading:false
    };
  },
  watch: {
    // charge: {
    //   handler(newValue) {
    //     let totalUserPayMoney = 0;
    //     if (this.charge.details.length > 0) {
    //       this.charge.details.forEach((item) => {
    //         item.countMoney = item.money * item.amount;
    //         //prescriptionType处方类型 0方剂(饮片) 处方 1 成药处方 2 输液处方
    //         if (item.orderType == 1 && item.prescriptionType == 0) {
    //           totalUserPayMoney = totalUserPayMoney + item.countMoney * parseInt(item.dosage);
    //         } else {
    //           totalUserPayMoney = totalUserPayMoney + item.countMoney;
    //         }
    //       });
    //     }
    //     this.charge.totalMoney = totalUserPayMoney;
    //     console.log(totalUserPayMoney)
    //   },
    //   deep: true,
    // },
  },
  mounted() {

    if (this.type == 1) {
      this.getChargeDetail();
    } else {
      this.paymentDetail();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerZ);
  },
  methods: {
    chargePayWayInput(e){
      clearInterval(this.timer);
      clearInterval(this.timerZ);
      this.qRcodeUrl = ''
      this.qRcodeUrlZ = ''
    },
    AllMessages() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
          let totalMoney = 0
          totalMoney = (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2)
          if (_this.UserBalance.assetsBalance * 1 < totalMoney) {
            _this.assetsBalanceIsTrue = true;
          } else {
            _this.assetsBalanceIsTrue = false;
          }
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    getPayPwd() {
      const href = this.$router.resolve("../personal/resetPassword");
      window.open(href.href, "_blank");
    },
    goPremangeseeDtail() {
      this.$router.push({
        path: "./PremangeseeDtail",
        query: {
          id: this.detailInfo.patientDiagnoseId,
          isFalse1: false,
        },
      });
    },
    CloseBtn(formName) {
      this.setChargeDialog = false;
      this.$refs[formName].resetFields();
    },
    SaveBtn(formName) {
      var _this = this;
      _this.nosucecessloading = true
           clearInterval(_this.timer);
       clearInterval(_this.timerZ);
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: "rgba(0, 0, 0, 0.5)",
          });
          _this.charge.payMoney = _this.charge.totalMoney - _this.charge.discountsMoney - _this.charge.debtMoney - _this.charge.totalPayMoney;
          let form = {
            id: _this.charge.id,
            details: _this.charge.details,
            paymentType:3,
            payments: [
              {
                discountsMoney: _this.charge.discountsMoney,
                debtUserName: _this.charge.debtUserName,
                debtUserPhone: _this.charge.debtUserPhone,
                debtMoney: _this.charge.debtMoney,
                payWay: _this.charge.payWay,
                payMoney: _this.charge.payMoney.toFixed(2),
                totalMoney: _this.charge.totalMoney.toFixed(2) - _this.charge.totalPayMoney.toFixed(2),
              },
            ],
          };
          if (_this.charge.payWay == 0) {
            console.log(form);
            _this.outpatientAuthorizationDomain.getIsSetPayPassword(
              function (data) {
                loading.close();
                _this.outpatientAuthorizationDomain.getIsPayPassword(
                  {
                    payPassword: _this.charge.payPwd,
                  },
                  function (data) {
                    loading.close();
                    _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                      loading.close();
                      _this.chargedialogVisible = false;
                      // _this.$router.go(-1);
                          setTimeout(()=>{
                            _this.nosucecessloading = false
                            _this.$router.push({
                              name: "orderList",
                            })
                          },1000)
                    });
                  },
                  function (error) {
                    _this.nosucecessloading = false
                    loading.close();
                    _this.$message({
                      type: "info",
                      message: "支付密码错误",
                    });
                  }
                );
              },
              function (error) {
                loading.close();
                _this.nosucecessloading = false
                _this
                  .$confirm("未设置支付密码？去设置", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                  .then(() => {
                    const href = _this.$router.resolve("../personal/resetPassword");
                    window.open(href.href, "_blank");
                  });
              }
            );
          } else if (_this.charge.payWay == 1) {
            _this.OutpatientDomain.DrugPayMent(
              form,
              function (data) {
                loading.close();
                _this.nosucecessloading = false
                _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
                _this.weChatInfo = data.data;
                var isSuccess = false;
                _this.timer = window.setInterval(() => {
                  setTimeout(function () {
                    _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                      _this.weChatInfo,
                      function (data2) {
                        isSuccess = data2.data.isSuccess;
                        if (isSuccess) {
                          clearInterval(_this.timer);
                          _this.$message({
                            type: "success",
                            message: "支付成功!",
                          });
                          _this.chargedialogVisible = false;
                          
                          // _this.$router.go(-1);
                          setTimeout(()=>{
                            _this.$router.push({
                              name: "orderList",
                            })
                          },1000)
                    
                        }
                      },
                      function (error) {
                        console.log(error);
                      }
                    );
                  }, 0);
                }, 5000);
                // setTimeout(() => {
                //     if (!isSuccess) {
                //         clearInterval(timer)
                //         if (paymentDialog.value) {
                //             proxy.$message({
                //                 type: 'error',
                //                 message: '支付超时!'
                //             });
                //             getList();
                //         }
                //     }
                // }, 300000)
              },
              function (error) {
                loading.close();
                let jsonObj = JSON.parse(error.msg);
                _this.$message({
                  message: jsonObj.msg,
                  type: "error",
                });
                console.log(error);
              }
            );
          } else {
            _this.OutpatientDomain.DrugPayMent(
              form,
              function (data) {
                loading.close();
                _this.nosucecessloading = false
                _this.qRcodeUrlZ = data.data.alipayData;
                _this.weChatInfo = data.data;
                var isSuccess = false;
                _this.timerZ = window.setInterval(() => {
                  setTimeout(function () {
                    _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                      {orderNo: _this.weChatInfo.orderNo,
                        attach: "MZPayment"
                      },
                      function (data2) {
                        isSuccess = data2.data.isSuccess;
                        if (isSuccess) {
                          clearInterval(_this.timerZ);
                          _this.$message({
                            type: "success",
                            message: "支付成功!",
                          });
                          _this.chargedialogVisible = false;
                          // _this.$router.go(-1);
                          setTimeout(()=>{
                            _this.$router.push({
                              name: "orderList",
                            })
                          },1000)
                        }
                      },
                      function (error) {
                        console.log(error);
                      }
                    );
                  }, 0);
                }, 5000);
                // setTimeout(() => {
                //     if (!isSuccess) {
                //         clearInterval(timer)
                //         if (paymentDialog.value) {
                //             proxy.$message({
                //                 type: 'error',
                //                 message: '支付超时!'
                //             });
                //             getList();
                //         }
                //     }
                // }, 300000)
              },
              function (error) {
                loading.close();
                let jsonObj = JSON.parse(error.msg);
                _this.$message({
                  message: jsonObj.msg,
                  type: "error",
                });
                console.log(error);
              }
            );
          }
        } else {
          _this.nosucecessloading = false
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkDebtMoney() {
      this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney - this.charge.debtMoney;
      this.charge.change = 0;
    },
    checkMoney() {
      let payMoney = this.charge.totalMoney - this.charge.discountsMoney;
      if (this.charge.credit) {
        payMoney = payMoney - this.charge.debtMoney;
      }
      if (this.charge.payment < payMoney) {
        this.$message({
          message: `付款金额不能小于${payMoney}`,
          type: "error",
        });
        this.charge.payment = undefined;
        return;
      } else {
        this.charge.change = this.charge.payment + this.charge.debtMoney - (this.charge.totalMoney - this.charge.discountsMoney);
        if (this.charge.credit) {
          this.charge.change = this.charge.payment + this.charge.debtMoney - (this.charge.totalMoney - this.charge.discountsMoney);
        } else {
          this.charge.change = this.charge.payment - (this.charge.totalMoney - this.charge.discountsMoney);
        }
      }
    },
    handleChange() {
      this.charge.debtUserName = null;
      this.charge.debtUserPhone = null;
      this.charge.debtMoney = undefined;
      this.charge.change = null;
      if (!this.charge.credit) {
        this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney;
        this.charge.debtMoney = 0;
      }
    },
    handleSelectionChange(val, orderType) {
      switch (orderType) {
        case 0:
          this.multipleSelection0 = val;
          break;
        case 1:
          this.multipleSelection1 = val;
          break;
        case 2:
          this.multipleSelection2 = val;
          break;
        case 3:
          this.multipleSelection3 = val;
          break;
        case 4:
          this.multipleSelection4 = val;
          break;
        case 5:
          this.multipleSelection5 = val;
          break;
      }
      this.computeSubData();
    },
    computeSubData() {
      if (this.type == 1) {
        this.charge.details = [];
        this.charge.totalMoney = 0;
        this.charge.details = [...this.multipleSelection0, ...this.multipleSelection1, ...this.multipleSelection2, ...this.multipleSelection3, ...this.multipleSelection4, ...this.multipleSelection5];
        this.charge.details.forEach((item) => {
          this.charge.totalMoney = this.charge.totalMoney + item.countMoney;
        });
        this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney;
      } else if (this.type == 2) {
        this.refund.details = [];
        this.refund.totalMoney = 0;
        this.refund.details = [...this.multipleSelection0, ...this.multipleSelection1, ...this.multipleSelection2, ...this.multipleSelection3, ...this.multipleSelection4, ...this.multipleSelection5];
        this.refund.details.forEach((item) => {
          this.refund.totalMoney = this.refund.totalMoney + item.countMoney;
        });
      }
    },
    /**
     * 根据状态禁用勾选退款
     */
    checkboxSelect(row, rowIndex) {
      // 0 待支付 1 已支付 -1 已退款
      if (this.type == 2) {
        if (row.orderState == 1 && this.detailInfo.discountsMoney == 0) {
          return true;
        } else {
          return false;
        }
      } else if (this.type == 1) {
        return false;
      }
    },
    /**
     * 根据状态禁用勾选退款
     */
    checkboxSelect1(row, rowIndex) {
      // 0 待支付 1 已支付 -1 已退款
      // if (row.orderState == 0) {
      //   return true
      // } else {
      //   return false
      // }
      return false;
    },
    initData() {
      this.charge.id = this.detailInfo.id;
      this.charge.details = [];
      this.charge.discountsMoney = 0; //优惠金额
      this.charge.debtUserName = null;
      this.charge.debtUserPhone = null;
      this.charge.debtMoney = 0; //挂账总金额
      this.charge.payWay = 1; //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
      this.charge.payMoney = 0; //支付金额
      this.charge.totalMoney = 0;
      this.charge.payment = null; //输入金额
      this.charge.change = 0; //找零
      this.charge.credit = false;
    },
    /**
     * 待收费详情
     */
    getChargeDetail() {
      var _this = this;
      _this.OutpatientDomain.DrugPayMentDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data;
          _this.patientId = _this.detailInfo.patientId;
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId;
          if (_this.patientId) {
            _this.getDetail();
            _this.getPatientDiagnose();
            _this.getMZPrescriptionDetail();
          }
          _this.initData();
          _this.AllMessages();
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] };
          if (_this.detailInfo.details.length > 0) {
            console.log(_this.detailInfo.details)
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || [];
              rest.orderType = orderType;
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId);
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].specification;
                  rest.specificationUnit = drugOrg[0].specificationUnit;
                  rest.productFactory = drugOrg[0].productFactory;
                  rest.isSeparate = drugOrg[0].isSeparate;
                  rest.separateNumber = drugOrg[0].separateNumber;
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit;
                }
              }
              _this.orderGrouped[orderType].push(rest);
              if (orderType == 3 || orderType == 4 || orderType == 5 || orderType == 6 || orderType == 7 || orderType == 8) {
                _this.orderGrouped[99].push(rest);
              }
            });
            _this.charge.details = _this.detailInfo.details;
            let totalUserPayMoney = 0;
            if (_this.charge.details.length > 0) {
              _this.charge.details.forEach((item) => {
                item.countMoney = item.money * item.amount;
                //prescriptionType处方类型 0方剂(饮片) 处方 1 成药处方 2 输液处方
                if (item.orderType == 1 && item.prescriptionType == 0) {
                  totalUserPayMoney = totalUserPayMoney + item.countMoney * parseInt(item.dosage);
                } else {
                  totalUserPayMoney = totalUserPayMoney + item.countMoney;
                }
              });
            }
            //_this.charge.totalMoney = totalUserPayMoney;
            _this.charge.totalMoney = _this.detailInfo.totalMoney;
            _this.charge.totalPayMoney = _this.detailInfo.totalPayMoney;
            // _this.$nextTick(function () {
            //   _this.defaultChecked();
            // });
          }
        },
        function (err) {
          console.log(err);
        }
      );
    },

    selectAllChange(selection, orderType) {
      if (this.type == 1) {
        // 如果选中的数目与请求到的数目相同就选中子节点，否则就清空选中
        if (selection && selection.length === this.orderGrouped[orderType].length) {
          selection.forEach((item) => {
            this.$set(item, "checked", false);
          });
          switch (orderType) {
            case 0:
              this.$refs.multipleSelection0.clearSelection();
              break;
            case 1:
              this.$refs.multipleSelection1.clearSelection();
              break;
            case 2:
              this.$refs.multipleSelection2.clearSelection();
              break;
            case 3:
              this.$refs.multipleSelection3.clearSelection();
              break;
            case 4:
              this.$refs.multipleSelection4.clearSelection();
              break;
            case 5:
              this.$refs.multipleSelection5.clearSelection();
              break;
          }
        } else {
          this.orderGrouped[orderType].forEach((v, i) => {
            switch (orderType) {
              case 0:
                this.$refs.multipleSelection0.toggleRowSelection(v, true);
                break;
              case 1:
                this.$refs.multipleSelection1.toggleRowSelection(v, true);
                break;
              case 2:
                this.$refs.multipleSelection2.toggleRowSelection(v, true);
                break;
              case 3:
                this.$refs.multipleSelection3.toggleRowSelection(v, true);
                break;
              case 4:
                this.$refs.multipleSelection4.toggleRowSelection(v, true);
                break;
              case 5:
                this.$refs.multipleSelection5.toggleRowSelection(v, true);
                break;
            }
          });
        }
      }
    },
    defaultChecked() {
      if (this.orderGrouped[0].length > 0) {
        this.orderGrouped[0].forEach((v, i) => {
          this.$refs.multipleSelection0.toggleRowSelection(v, true);
        });
      }
      if (this.orderGrouped[1].length > 0) {
        this.orderGrouped[1].forEach((v, i) => {
          this.$refs.multipleSelection1.toggleRowSelection(v, true);
        });
      }
      if (this.orderGrouped[2].length > 0) {
        this.orderGrouped[2].forEach((v, i) => {
          this.$refs.multipleSelection2.toggleRowSelection(v, true);
        });
      }
      if (this.orderGrouped[3].length > 0) {
        this.orderGrouped[3].forEach((v, i) => {
          this.$refs.multipleSelection3.toggleRowSelection(v, true);
        });
      }
      if (this.orderGrouped[4].length > 0) {
        this.orderGrouped[4].forEach((v, i) => {
          this.$refs.multipleSelection4.toggleRowSelection(v, true);
        });
      }
      if (this.orderGrouped[5].length > 0) {
        this.orderGrouped[5].forEach((v, i) => {
          this.$refs.multipleSelection5.toggleRowSelection(v, true);
        });
      }
    },
    /**
     * 已收费详情
     */
    paymentDetail() {
      var _this = this;
      _this.OutpatientDomain.PaymentedDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data;
          _this.patientId = _this.detailInfo.patientId;
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId;
          if (_this.patientId) {
            _this.getDetail();
            _this.getPatientDiagnose();
            _this.getMZPrescriptionDetail();
          }
          _this.AllMessages()
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 99: [] };
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || [];
              rest.orderType = orderType;
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId);
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].specification;
                  rest.specificationUnit = drugOrg[0].specificationUnit;
                  rest.productFactory = drugOrg[0].productFactory;
                  rest.isSeparate = drugOrg[0].isSeparate;
                  rest.separateNumber = drugOrg[0].separateNumber;
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit;
                  rest.remark = drugOrg[0].remark;
                }
              }
              _this.orderGrouped[orderType].push(rest);
              if (orderType == 3 || orderType == 4 || orderType == 5 || orderType == 6 || orderType == 7 || orderType == 8) {
                _this.orderGrouped[99].push(rest);
              }
            });
            _this.detailInfo.showTotalMoney = _this.detailInfo.totalMoney;
            if (_this.orderGrouped[5].length > 0) {
              _this.detailInfo.showTotalMoney = (_this.detailInfo.totalMoney - _this.orderGrouped[5][0].countMoney).toFixed(2);
            }
            if (_this.orderGrouped[8].length > 0) {
              _this.detailInfo.showTotalMoney = (_this.detailInfo.showTotalMoney - _this.orderGrouped[8][0].countMoney).toFixed(2);
            }
            if (_this.detailInfo.discountsMoney > 0) {
              _this.refund.totalMoney = 0;
              _this.refund.details = [..._this.orderGrouped[0], ..._this.orderGrouped[2], ..._this.orderGrouped[3], ..._this.orderGrouped[4], ..._this.orderGrouped[5]];
              console.log(_this.refund.details);
              _this.refund.details.forEach((item) => {
                //处方类型 0方剂(饮片) 处方 1 成药处方 2 输液处方
                if (item.orderType == 1 && item.prescriptionType == 0) {
                  _this.refund.totalMoney = _this.refund.totalMoney + item.countMoney * parseInt(item.dosage);
                } else {
                  _this.refund.totalMoney = _this.refund.totalMoney + item.countMoney;
                }
              });
              _this.refund.payments[0].payMoney = _this.refund.totalMoney - _this.detailInfo.discountsMoney - _this.detailInfo.debtMoney;
            }
          }
        },
        function (err) {
          console.log(err);
        }
      );
    },
    getDetail() {
      var _this = this;
      _this.drugPatientDomain.GetPatient(
        _this.patientId,
        function (data) {
          _this.patientInfo = data.data;
        },
        function (err) {}
      );
    },
    getPatientDiagnose() {
      var _this = this;
      _this.drugPatientDomain.GetPatientDiagnoseDetail(
        _this.patientDiagnoseId,
        function (data) {
          _this.patientDiagnoseInfo = data.data;
        },
        function (err) {}
      );
    },
    getMZPrescriptionDetail() {
      var _this = this;
      _this.OutpatientDomain.MZPrescriptionDetail(
        _this.patientDiagnoseId +'/true',
        function (data) {
          _this.mZPrescriptionInfo = data.data;
          _this.getPrintPrescription();
        },
        function (err) {}
      );
    },
    getPrintPrescription() {
      var _this = this;
      _this.drugPatientDomain.MZPrintPrescription(
        _this.patientDiagnoseId,
        this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId ? this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId : this.mZPrescriptionInfo.mzPrescriptionOut.userId,
        _this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditPharmacistId ? _this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditPharmacistId : 0,
        function (data) {
          _this.mZPrintPrescription = data.data;
        },
        function (err) {}
      );
    },
    CloseChargeBtn(formName) {
      this.setChargeDialog = false;
      this.$refs[formName].resetFields();
    },
    CloseRefundBtn(formName) {
      this.setRefundDialog = false;
      this.$refs[formName].resetFields();
    },
    toCharge() {
      if (this.charge.details.length == 0) {
        this.$message({
          message: "请选择收费项目",
          type: "warning",
        });
        return;
      }
      this.charge.payment = this.charge.totalMoney - this.charge.discountsMoney;
      this.setChargeDialog = true;
    },
    toRefundAll() {
      var _this = this;
      _this.OutpatientDomain.DrugRefund(
        _this.refund,
        function (data) {
          _this.$router.push({
            path: "./CreateOrder",
            query: {
              orderId: _this.orderId,
            },
          });
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    toDrugRefundApply() {
      var _this = this;
      _this
        .$confirm("确认是否退款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.OutpatientDomain.DrugRefundApply(
            _this.orderId,
            function (data) {
              _this.$message({
                message: "退费申请已提交，请耐心等待",
                type: "success",
              });
              _this.goBack();
            },
            function (error) {
              loading.close();
              let jsonObj = JSON.parse(error.msg);
              _this.$message({
                message: jsonObj.msg,
                type: "error",
              });
              console.log(error);
            }
          );
        })
        .catch(() => {});
    },
    toRefund() {
      if (this.detailInfo.discountsMoney == 0) {
        if (!this.refund.details.length) {
          this.$message({
            message: "请选择退费项目",
            type: "warning",
          });
          return;
        }
        this.refund.payments[0].payMoney = this.refund.totalMoney - this.detailInfo.discountsMoney - this.detailInfo.debtMoney;
      }
      if (this.refund.details.length == 0 && this.orderGrouped[1].length > 0) {
        this.$message({
          message: "处方不可退",
          type: "warning",
        });
        return;
      }

      this.setRefundDialog = true;
    },
    DrugRefund() {
      var _this = this;
      _this.OutpatientDomain.DrugRefund(
        _this.refund,
        function (data) {
          _this.$router.push({
            path: "./CreateOrder",
            query: {
              orderId: _this.orderId,
            },
          });
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    saveRefund(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: "rgba(0, 0, 0, 0.5)",
          });
          _this.OutpatientDomain.DrugRefund(
            _this.refund,
            function (data) {
              loading.close();
              _this.$message({
                message: "退费成功",
                type: "success",
              });
              _this.setRefundDialog = false;
              _this.$router.go(-1);
              // if (_this.detailInfo.discountsMoney == 0) {
              //   _this.$router.go(-1)
              // } else {
              //   _this.$router.push({
              //     path: './CreateOrder',
              //     query: {
              //       orderId: _this.orderId,
              //     }
              //   })
              // }
            },
            function (error) {
              loading.close();
              let jsonObj = JSON.parse(error.msg);
              _this.$message({
                message: jsonObj.msg,
                type: "error",
              });
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    addPatient() {
      this.$router.push({
        path: "./AddPatient",
        query: {},
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pageBox {
  text-align: center;
  margin-top: 10px;
}

.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}

.titleTopLeftImg {
  width: 123px;
  height: 24px;
}

.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}

.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}

.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}

.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}

.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.titleTopRight div {
  margin-left: 10px;
}

.titleTopLine {
  width: 100%;
  height: 12px;
}

.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}

.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  // max-height: 750px;
  overflow: auto;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}

.titleTopCenterCenterTop {
  background-color: #ffffff;
  // padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
  overflow-y: auto;
}

.flex-l {
  display: flex;
  align-items: center;
}

.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.noflex-l-s {
  display: flex;
  justify-content: space-around;
}

.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  /* font-weight: bold; */

  color: #333333;
  padding-bottom: 0;
  text-align: left;
}

.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}

.ageInput {
  width: 80%;
}

.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}

.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}

.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}

.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}

.stepInput {
  width: 60%;
  margin-right: 10px;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}

.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}

.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}

.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding: 0 40px;

    .top-info {
      .t1 {
        margin-right: 30px;
      }

      .t2 {
        color: #999999;
      }

      .right-i {
        margin-right: 10%;
      }

      .userName {
        margin-left: 10px;
      }

      .info-div {
        margin-top: 10px;
      }
    }

    .title {
      height: 60px;
      line-height: 60px;
      font-weight: bold;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      // border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 10px 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #fff7f7;
      border: 1px solid #f4d3d3;
      border-radius: 8px;
      margin: 20px 0;

      .num1 {
        margin: 0 10px;
      }

      .num2 {
        color: #ff0000;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .numDiv2 {
      background: #fafafa;
      border: 1px solid #e6e6e6;

      .right-num {
        // margin-right: 10%;
      }

      .center-num {
        // margin: 0 20px;
      }

      .desc {
        margin-right: 2%;
        width: 32%;
      }

      .desc:nth-child(3n) {
        margin-right: 0;
      }

      .type0 {
        color: #67c23a !important;
      }

      .type1 {
        color: #f56c6c !important;
      }

      .type2 {
        color: #e6a23c !important;
      }

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #999999;
      }

      .t3 {
        color: #fe0000;
      }

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .infoDiv {
      // margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #5c83f2;
      }
    }
  }
}
</style>
